import { TableRow } from "../models/common-table";
import { REGEX_QUOTE } from "./validation";

export type SortOrder = "ASC" | "DESC";

const REGEX_COMMA_QUOTE = /[",]/;

export function defaultSerializer(row: TableRow, keys: string[]): string | null {
  if ((keys || []).length === 0) {
    return null;
  }
  return keys
    .map((heading: string, hIdx: number) => {
      const cell = row[hIdx];
      if (!cell) {
        return "";
      }
      return cell.rawValue ?? (Array.isArray(cell.value) ? cell.value[0] : cell.value);
    })
    .map((i) => i ?? "") // fetch value if present
    .map((i) => (typeof i === "string" ? i : JSON.stringify(i))) // try coerce to string
    .map((i) => i.replace(REGEX_QUOTE, '""')) // escape double quote
    .map((i) => (REGEX_COMMA_QUOTE.test(i) ? '"' + i + '"' : i)) // add double quote string identifer
    .join(",");
}

export type DownloadType = "all" | "selected" | "filtered" | "selectedColumns";
export const CLASS_EXTERNAL = "";
export const CLASS_INTERNAL = "bg-light";
