import { ChatbotAnswer, Creativity, LLModel, QueryDomain } from "../models/llm";
import { CloudProvider } from "../models/vcloud-api";

export const QUOTA_N_HIST_ITEMS_CURRENT_PAGE = 20;
export const QUOTA_N_HIST_ITEMS = 41;
export const QUOTA_HIST_ITEM_LEN = 3000;
export const N_QUESTIONS = 5;
export const N_SUGGESTED_REPORTS = 3;
export const N_DATA_SENT = 20;
export const groupByFieldRegex = /^Value(\d+)$/;
export const CREATIVITY_VALUES: Creativity[] = ["VERY_HIGH", "HIGH", "MEDIUM", "LOW", "EXTREME"];
export const DOMAINS: QueryDomain[] = ["billing", "assets", "recommendations"];
export const REGEX_DATE =
  /^\s*(?<date>(?<year>20[0-9]{2})[^0-9]*(?<month>[0-9]{2})[^0-9]*(?<day>[0-9]{2})?)[^0-9]*(?<time>(?<hour>[0-9]{2})[^0-9]*(?<minute>[0-9]{2})[^0-9]*(?<seconds>[0-9]{2})?[^0-9]*(?<miliseconds>[0-9]+)?)?\s*$/;
export const SECOND = 1000;
export const MODEL_CHEAP_V1: LLModel = "gemini-1.5-flash-001";
export const MODEL_CHEAP_V2: LLModel = "gemini-1.5-flash-002";
export const MODEL_CHEAP: LLModel = MODEL_CHEAP_V2;
export const MODEL_EXPENSIVE_V1: LLModel = "gemini-1.5-pro-001";
export const MODEL_EXPENSIVE_V2: LLModel = "gemini-1.5-pro-002";
export const MODEL_EXPENSIVE: LLModel = MODEL_EXPENSIVE_V2;
export const CONFIG_ATTRS_DESCRIPTION: { [key: string]: string } = {
  role: "My role (position) in the company is {placeholder}",
  application: "We are working on the following application: {placeholder}",
  goals: "My primary goal is to: {placeholder}",
  instructions: "{placeholder}",
  cloudProvider: "I primarily work with {placeholder}",
};
export const CONFIG_ATTRS: string[] = Object.keys(CONFIG_ATTRS_DESCRIPTION);
export const MODEL_CONFIG = {
  max_output_tokens: 1000,
  top_p: {
    EXTREME: 0.95,
    VERY_HIGH: 0.93,
    HIGH: 0.92,
    MEDIUM: 0.9,
    LOW: 0.8,
    VERY_LOW: 0.75,
  },
  temperature: {
    EXTREME: 1.5,
    VERY_HIGH: 1.35,
    HIGH: 1.15,
    MEDIUM: 1.0,
    LOW: 0.9,
    VERY_LOW: 0.85,
  },
};
export const AI_NAME = "TOBi";
export const AGENTS = ["User", AI_NAME, "VCloudSmart API", "VCloudSmart UI", "PCS Portal API", "PCS Portal UI"];
export const TOOLTIPS_FOR_WHO: { [key: string]: string } = {
  "VCloudSmart API": `Response from the API which queries your ${DOMAINS.join("/")} data.`,
};
export const DEFAULT_QUESTIONS = [
  "What is my cost consumption in AWS over the period of last 12 months? Break it down by month.",
  "How much have I spent in Azure in the previous quarter? Break it down by month.",
  "What are my most expensive services in Google Cloud? Break down cost by service.",
  "List my cost optimization recommendations in AWS",
  "What can you tell me about the current page and if available information displayed?",
];
export const GREETING: ChatbotAnswer[] = [
  {
    message: `
    
<p>Hi there 👋 ! I am ${AI_NAME}, your friendly <u>AI assistant</u> here to help you understand and optimize your cloud ☁️ usage in:</p>

<ul>
  <li><u>Amazon Web Services (AWS)</u></li>
  <li><u>Google Cloud Platform (GCP)</u></li>
  <li><u>Microsoft Azure</u></li>
  <li><u>Oracle Cloud Infrastructure Dedicated Customer Region (DRCC)</u></li>
</ul>

<p>I can help you to visualize 📈 and understand your:</p>

<ul>
  <li>Cloud costs 💵</li>
  <li><u>Resources</u> 📦</li>
  <li>Potential savings 💡</li>
</ul>

<p>
  I also explain concepts and best practices in cloud, <u>DevOps</u>, and <u>FinOps</u>, and compare services between cloud providers. You can ask me which pages (reports) have the information you need and you can also ask about the current VCloudSmart report and the data displayed on the screen.
  <br>
  <br>
  Think of me as personal guide to the world of <u>cloud computing</u> 😊! 
</p>

`.trim(),
    who: AI_NAME,
    type: "text",
  },
];
export const INTERPRET_DATA_QUERY =
  "Consider the data received from the API, can you summarize it and if possible find any insights, trends or patterns in it?";

export const REGEX_DEFINITION = /^[-_ )(a-zA-Z0-9]+$/;
export const CLOUD_PROVIDERS_ALL = ["aws", "gcp", "azure", "drcc", "oci"] as CloudProvider[];
export const CLOUD_PROVIDERS_ENABLED = ["aws", "gcp", "azure", "drcc"] as CloudProvider[];
export const SAVE_EVERY_INTERVAL = 15 * SECOND;
export const RACE_N_DATA_QUERY = 4;
export const RACE_N_CLASSIFICATION = 2;
