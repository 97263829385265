<div class="mb-5 mt-5" *ngIf="cloudProviders.length > 0">
  <div>
    <h4 class="h4">Your Permissions</h4>
    <img id="vf-loader" class="d-block m-auto" *ngIf="loading" src="/assets/images/spinner_logo.svg" alt="spinner" />
    <div class="p-3 mt-2 shadow bg-white" *ngIf="!loading">
      <table>
        <tbody>
          <tr *ngFor="let csp of cloudProvidersNames">
            <td *ngIf="cloudProvidersNames.length > 1">
              <strong class="text-primary pr-3">
                {{ csp | uppercase }}
              </strong>
            </td>
            <td class="pr-2">
              <ng-container *ngIf="permissions[csp]?.global || (permissions[csp]?.resources?.length ?? 0) > 0"
                >✓</ng-container
              >
              <ng-container *ngIf="!permissions[csp]?.global && (permissions[csp]?.resources?.length ?? 0) === 0"
                >✗</ng-container
              >
            </td>
            <td [innerHTML]="permissions[csp].summary"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <p *ngIf="canRequestPerms" class="mt-4">
    <strong>HINT</strong>: you can request permissions by clicking on <code>Access Request Form</code> on the
    <a fragment="vcloudsmart" routerLink="/dashboard">VCloudSmart dashboard</a>.
  </p>
</div>
