import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, SimpleChanges } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { HttpClient } from "@angular/common/http";
import { of, Subscription } from "rxjs";
import { LoggerService } from "../../services/logger.service";
import { CommonModule } from "@angular/common";

import { IconPack } from "../../models/icons";
import { environment } from "../../../../environments/environment";

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: "app-vficon",
  templateUrl: "./vficon.component.html",
  styleUrls: ["./vficon.component.css"],
})
export class VFIconComponent implements OnChanges, OnDestroy {
  @Input() iconName: string;
  @Input() iconPack: IconPack = "source-mid-render-light-icons";
  @Input() size: string = "25px";
  @Input() color: string = "rgb(13, 13, 13)";

  public html;
  private subscriptions: Subscription[] = [];

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  constructor(
    private sanitiser: DomSanitizer,
    private log: LoggerService,
    private http: HttpClient,
    private cdr: ChangeDetectorRef
  ) {}

  private renderIcon(iconName: string, iconPack: string, size: string): void {
    if (environment.cypress) {
      this.html = "";
      return;
    }

    this.log.debug({ iconName, iconPack, size });

    const url: string = `/assets/icons/${iconPack}/${iconName}.svg`;
    const maybeIcon: string = localStorage.getItem(url);

    if (maybeIcon) {
      this.log.debug("found icon ", url, " in local storage");
    }

    this.subscriptions.push(
      // @ts-ignore
      (maybeIcon
        ? of(maybeIcon)
        : this.http.get<string>(url, {
            // @ts-ignore
            responseType: "text",
          })
      )
        // @ts-ignore
        .subscribe((response: string) => {
          const html: string = response;
          localStorage.setItem(url, html);
          const svg = document.createElement("svg");
          // @ts-ignore
          svg.innerHTML = html;
          if (iconPack === "source-system-icons") {
            // @ts-ignore
            svg.children[0].style.stroke = this.color;
          }
          // @ts-ignore
          svg.children[0].style.height = size;
          // @ts-ignore
          svg.children[0].style.width = size;
          this.html = this.sanitiser.bypassSecurityTrustHtml(svg.children[0].outerHTML);
          this.cdr.detectChanges(); // Manually trigger change detection
        })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.log.debug({ changes });
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.subscriptions = [];
    this.renderIcon(
      (changes.iconName || {}).currentValue || (changes.iconName || {}).previousValue || this.iconName,
      (changes.iconPack || {}).currentValue || (changes.iconPack || {}).previousValue || this.iconPack,
      (changes.size || {}).currentValue || (changes.size || {}).previousValue || this.size
    );
  }
}
