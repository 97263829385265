import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router";
import { AuthenticationService } from "../services/authentication.service";
import { LoggerService } from "../services/logger.service";
import Swal from "sweetalert2";
import { of } from "rxjs";
import { catchError } from "rxjs/operators";
import { TenantService } from "../services/tenant.service";
import { MetricCollectionService } from "../services/metric-collection.service";
import { environment } from "../../../environments/environment";
import { EventsService } from "../services/events.service";

const CSPS = ["azure", "gcp", "oci", "drcc", "evo"];

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private log: LoggerService,
    private authN: AuthenticationService,
    private metrics: MetricCollectionService,
    private tenant: TenantService,
    private events: EventsService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    if (localStorage.getItem("runningCypressTest")) {
      return true;
    }
    if (this.events.loading.value) {
      this.events.loading.next(false);
    }
    const isLoggedIn = this.authN.isLoggedIn();

    if (isLoggedIn) {
      const url = route.pathFromRoot.map((v) => v.url.map((segment) => segment.toString()).join("/")).join("/");
      this.metrics.reportPageVisited(url);
      this.log.info({ route, state });
      if (this.authN.tenant === "trial") {
        this.log.info("trial user");
        for (const csp of CSPS) {
          for (const s of [state.url || "", route.fragment || ""]) {
            if (s.includes(`/${csp}/`) || s.endsWith(`/${csp}`) || s.startsWith(`${csp}/`) || s === csp) {
              return false;
            }
          }
        }
      }
      return isLoggedIn;
    }

    const email = this.authN.email;

    this.log.info({ route, state, email });

    let promise = new Promise((res, _) => res(null));

    if (email) {
      promise = this.authN
        .logout(email)
        .pipe(catchError(() => of(null)))
        .toPromise();
    }

    localStorage.setItem("navigateToUrlAfterAuth", location.pathname);

    await Swal.fire({
      icon: "warning",
      title: "Session Expired",
      text: "Click on the button below to re-authenticate.",
      confirmButtonColor: this.tenant.colors.primary,
      allowOutsideClick: false,
      confirmButtonText: "Reauthenticate",
      footer: "",
    });

    await promise;

    if (environment.local && location.hostname === "localhost") {
      this.authN.onLoginClickCognitoV2SignIn();
    } else {
      this.authN.onLoginClickAAD();
    }

    return false;
  }
}
