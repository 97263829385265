export const announcements: string[] = [
  `
  <h3 class="h3">Hybrid Data Fusion</h3>
  
  <hr>
  
  <p>
    We've recently updated the billing formulas for VPC Billing account<span class="text-primary"> specific period APR-24 to NOV-24</span > due to the introduction of a new credit for <u><b> Hybrid Data Fusion </b></u> (HDF) service.
  </p>
  
  <h3 class="h3">Summary of Changes:</h3>
  
  <ul class="m-0 p-0">
    <li>
      - <span class="font-weight-bold">Promotions Removed:</span> All promotions for this billing account have been eliminated.
    </li>
    <li>
      - <span class="font-weight-bold">HDF Charges:</span> HDF charges have been eliminated at the billing account level.
    </li>
  </ul>
  
  <h3 class="h5 font-weight-bold">The changes will affect your current projects in the following ways:</h3>
  
  <ul class="m-0 p-0">
    <li>
      - <span class="font-weight-bold">Cost Display Differences:</span> You might see different cost distributions between the GCP Console and VCloudSmart due to how the credit is applied.<span class="text-primary"> (if you want to match the VCS and GCP values, please remove the promotion from GCP console)</span>
    </li>
    <li>
      - <span class="font-weight-bold">No More Promotions:</span> Any promotions you had are now removed, so you won’t see those discounts anymore. </li>
    <li>
      - <span class="font-weight-bold">Cost Adjustments:</span> Costs of previous promotions are now added to your GCP costs, which could increase the amount shown.
    </li>
    <li>
      - <span class="font-weight-bold">HDF Charges Removed:</span> Charges for Hybrid Data Fusion (HDF) at the VPC billing account level are eliminated, which might reduce your overall costs.
    </li>
  </ul>
  `,
].map((a) => a.trim());
