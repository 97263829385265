import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TooltipModule } from "ngx-bootstrap/tooltip";

type Size = "sm" | "md" | "lg";

type Phase = "NEW" | "BETA" | "ALPHA" | "PREVIEW";

const DESCRIPTIONS = {
  ALPHA:
    "The section is still being developed and tested. It is accessible only by PCS and/or small group of selected people.",
  BETA: "The section is usable but may improve/change and is still being tested. We offer limited support in case of issues.",
  PREVIEW:
    "The section is usable but may improve/change and is still being tested. We welcome users to try it out and give us feedback. We offer limited support in case of issues.",
  NEW: "The section has recently been released. Feel free to explore it and give your feedback to the development team.",
};

@Component({
  standalone: true,
  imports: [CommonModule, TooltipModule],
  selector: "app-release-phase-label",
  templateUrl: "./release-phase-label.component.html",
  styleUrls: ["./release-phase-label.component.scss"],
})
export class ReleasePhaseLabelComponent implements OnChanges {
  @Input() phase: Phase;
  @Input() size: Size = "sm";
  @Input() disableTooltip: boolean = false;
  @Input() clickable: boolean = true;

  public classes: { [c: string]: boolean } = {};
  public tooltip: string = "";

  ngOnChanges(changes: SimpleChanges) {
    if (changes.phase || changes.size) {
      this.classes = this.getClasses(this.phase, this.size, this.clickable);
      this.tooltip = this.getTooltip(this.phase);
    }
  }

  public getClasses(phase: Phase, size: Size, clickable: boolean): { [key: string]: boolean } {
    const classes = {
      "btn-not-clickable": !clickable,
      btn: true,
      "btn-sm": size === "sm",
      "btn-md": size === "md",
      "btn-lg": size === "lg",
      "font-weight-bold": true,
    };
    if (phase === "BETA") {
      classes["btn-beta"] = true;
    } else if (phase === "PREVIEW") {
      classes["btn-preview"] = true;
    } else if (phase === "ALPHA") {
      classes["btn-alpha"] = true;
    } else if (phase === "NEW") {
      classes["btn-new"] = true;
    } else {
      classes["btn-secondary"] = true;
    }
    return classes;
  }

  public getTooltip(phase: Phase): string | undefined {
    return DESCRIPTIONS[phase];
  }
}
