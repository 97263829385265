<nav class="nav flex-column shadow position-absolute">
  <div class="menu-container d-flex justify-content-center align-items-center mb-3 mt-5">
    <h2 class="text-center h2">
      <a id="vcs-btn" fragment="vcloudsmart" routerLink="/dashboard" class="navbar-hide"> vCloudSmart </a>
    </h2>
    <button class="btn float-right menu-icon">
      <app-vficon style="position: relative; bottom: 3px" iconPack="source-system-icons" iconName="menu"></app-vficon>
    </button>
  </div>

  <div class="navbar-hide d-flex flex-column">
    <hr class="w-100 mb-4" />

    <div class="px-4">
      <a
        *ngIf="location?.pathname !== '/vcloudsmart/assistant'"
        routerLink="/vcloudsmart/assistant"
        class="btn btn-primary h3 w-100 mt-1"
      >
        <span>AI Assistant</span>
      </a>
    </div>

    <ng-container *ngIf="isCurrentPageMigrated || location?.pathname === '/vcloudsmart/assistant'">
      <div class="mb-4 mt-3" *ngIf="currentTenant === 'vodafone' && location?.pathname !== '/vcloudsmart/assistant'">
        <h3 class="h3 ml-3 font-weight-bold">Version V2 ?</h3>
        <input
          type="checkbox"
          [disabled]="!reportState?.page?.target"
          [checked]="migratedVersionSelected"
          style="left: 20px; top: 3px"
          class="version-change-button ml-2 mb-2 position-relative"
          (change)="onVersionChange()"
        />
      </div>
      <div
        class="mb-4 {{ currentTenant === 'vodafone' ? '' : 'mt-3' }}"
        *ngIf="
          migratedVersionSelected ||
          (location?.pathname === '/vcloudsmart/assistant' && (currencies?.length ?? 0) !== 0)
        "
      >
        <h3 class="h3 ml-3 font-weight-bold">Currency</h3>
        <select
          [ngModel]="currency?.id"
          class="d-block ml-3"
          [disabled]="currencyChangeInProgress"
          (change)="handleCurrencyChange($event.target.value)"
          style="min-width: 8.35rem"
        >
          <option [value]="c.id" *ngFor="let c of currencies">
            {{ c?.text || c?.id }}
            <span *ngIf="c?.id !== defaultCurrency">
              ({{ c?.id }}/{{ defaultCurrency }} {{ c?.exchangeRate.toPrecision(4) }})
            </span>
            <span *ngIf="c?.id === defaultCurrency"> (default currency) </span>
          </option>
        </select>
      </div>
    </ng-container>

    <div class="mb-4">
      <h3 class="h3 ml-3 mb-3 font-weight-bold">Width</h3>

      <div class="form-check mb-3 ml-5" *ngFor="let size of iframeSizesKeys">
        <input
          class="form-check-input"
          type="radio"
          id="iframe-size-{{ size }}"
          (change)="handleSizeChange($event.target.value)"
          [value]="size"
          [checked]="currentSize === size"
        />
        <label class="form-check-label position-relative" style="top: 2px; left: 3px" for="iframe-size-{{ size }}">
          {{ size }}
        </label>
      </div>
    </div>

    <ng-container *ngFor="let csp of vcsConfig">
      <ng-container *ngIf="(csp?.sections?.length ?? 0) > 0 && !csp?.isHidden">
        <h3 class="h3" [attr.data-csp]="csp?.title">
          <button
            [class.activated-btn]="this.urlParams?.csp === csp?.id"
            class="btn font-weight-bold csp-title w-100"
            (click)="
              this.urlParams.csp = this.urlParams?.csp === csp?.id ? undefined : csp?.id; $event.stopPropagation()
            "
          >
            <h3 class="h3 csp-title-text float-left font-weight-bold">{{ csp?.title }}</h3>
            <app-vficon
              class="float-right"
              *ngIf="this.urlParams?.csp === csp?.id"
              iconPack="source-system-icons"
              iconName="chevron-down"
            ></app-vficon>
            <app-vficon
              class="float-right"
              *ngIf="this.urlParams?.csp !== csp?.id"
              iconPack="source-system-icons"
              iconName="chevron-up"
            ></app-vficon>
          </button>
        </h3>

        <ng-container *ngFor="let section of csp?.sections ?? []">
          <div
            *ngIf="!section?.isHidden && (section?.pages?.length ?? 0) > 0"
            [isAnimated]="true"
            [collapse]="this.urlParams?.csp !== csp.id"
          >
            <h4 class="h4 ml-4">
              <button
                [class.activated-btn]="this.urlParams?.section === section?.title"
                class="btn w-100"
                (click)="
                  this.urlParams.section = this.urlParams?.section === section?.title ? undefined : section?.title;
                  $event.stopPropagation()
                "
              >
                <span class="h4 float-left font-weight-bold section-title">
                  {{ section?.title }}
                  <app-vficon
                    *ngIf="section?.description"
                    size="15px"
                    style="position: relative; bottom: 3px; left: 3px; cursor: pointer"
                    [tooltip]="section?.description"
                    iconPack="source-system-icons"
                    iconName="info-circle"
                  ></app-vficon>
                </span>

                <app-vficon
                  class="float-right"
                  *ngIf="this.urlParams?.section === section?.title"
                  size="14px"
                  iconPack="source-system-icons"
                  iconName="chevron-down"
                ></app-vficon>
                <app-vficon
                  class="float-right"
                  size="14px"
                  *ngIf="this.urlParams?.section !== section?.title"
                  iconPack="source-system-icons"
                  iconName="chevron-up"
                ></app-vficon>
              </button>
            </h4>

            <ng-container *ngFor="let page of section?.pages ?? []">
              <div *ngIf="!page?.isHidden" [collapse]="this.urlParams?.section !== section?.title" [isAnimated]="true">
                <a
                  *ngIf="page"
                  [routerLink]="getReportLink(csp, section, page)"
                  class="nav-link py-3"
                  [class.disabled]="csp?.isDisabled || section?.isDisabled || page?.isDisabled"
                  [class.active]="isActive(csp, section, page)"
                >
                  <app-vficon
                    *ngIf="page?.iconName"
                    size="25px"
                    class="mr-3"
                    style="position: relative; bottom: 2px"
                    [iconName]="page?.iconName"
                    [iconPack]="page?.iconPack ?? 'source-system-icons'"
                  ></app-vficon>
                  <span>
                    {{ page?.title }}
                  </span>
                  <app-release-phase-label
                    *ngIf="page?.stage"
                    [clickable]="false"
                    [disableTooltip]="true"
                    class="float-right mx-2 my-auto d-sm-none d-md-none d-lg-inline d-xl-inline"
                    style="position: relative; bottom: 5px"
                    [phase]="page?.stage"
                  ></app-release-phase-label>
                </a>
              </div>
            </ng-container>
            <hr />
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</nav>
