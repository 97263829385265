import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CloudProvider } from "../../models/vcloud-api";
import { Subscription } from "rxjs";
import { LoggerService } from "../../services/logger.service";
import { AuthorisationService } from "../../services/authorisation.service";
import { CloudProviderPermissions } from "../../models/permissions";
import { CLOUD_PROVIDERS_ALL } from "../../constant/llm";
import { RouterLinkWithHref } from "@angular/router";

@Component({
  standalone: true,
  imports: [CommonModule, RouterLinkWithHref],
  selector: "app-user-permissions-summary",
  templateUrl: "./user-permissions-summary.component.html",
  styleUrls: ["./user-permissions-summary.component.css"],
})
export class UserPermissionsSummaryComponent implements OnChanges, OnDestroy, OnInit {
  @Input() cloudProviders: CloudProvider[] = CLOUD_PROVIDERS_ALL;

  public permissions: { [csp: string]: CloudProviderPermissions } = {};
  public cloudProvidersNames: string[] = [];
  public canRequestPerms: boolean = false;
  public loading: boolean = false;

  private subscriptions: Subscription[] = [];

  constructor(private log: LoggerService, private auth: AuthorisationService) {}

  ngOnInit(): void {
    if (!this.loading) {
      this.ngOnChanges({});
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loading = true;
    this.ngOnDestroy();
    this.subscriptions.push(
      this.auth.describeUserPerms(this.cloudProviders).subscribe((s) => {
        this.permissions = s;
        this.cloudProvidersNames = Object.keys(s);
        this.canRequestPerms = Object.values(s).some((p) => !p.global);
        this.loading = false;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.subscriptions = [];
  }
}
