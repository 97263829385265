<div id="table-filter-accordion" class="mb-3">
  <div class="card br-6 box-shadow border-0">
    <div id="table-filter">
      <div class="card-body d-flex">
        <form class="d-flex w-100" [formGroup]="form" (ngSubmit)="onFilterFormSubmit()">
          <div class="d-flex align-items-center px-4">
            <app-vficon iconName="filter" iconPack="source-system-icons" style="padding-bottom: 2px"></app-vficon>
            <span class="font-weight-bold pl-2" style="font-size: 1.3rem; color: #7e7e7e">Filter</span>
          </div>
          <div class="row filter-container px-4 w-100">
            <div class="col searched ml-3 my-2" *ngFor="let filterItem of filterItems; index as idx">
              <span class="searched-column"
                >{{
                  (filterItem.columnLabel || filterItem.columnTitle) === "all"
                    ? "ALL"
                    : filterItem.columnLabel || filterItem.columnTitle
                }}
              </span>
              <span class="text-monospace">::</span>
              <span class="searched-text">{{ filterItem.searchText.join(" OR ") }}</span>
              <app-vficon
                iconName="cancel"
                style="cursor: pointer"
                (click)="removeFilterItem(idx)"
                tooltip="remove filter"
              ></app-vficon>
            </div>
            <div #searchTextContainer class="col search-box my-2" *ngIf="isSearchBoxOpen">
              <input
                #searchTextInput
                type="text"
                name="searchText"
                placeholder="Column Name"
                tooltip='type "Column Name :: Search Text" to filter data'
                class="search-text w-100"
                [formControl]="searchText"
                (keyup)="searchColumns($event)"
                data-cy="search-text-box"
              />
              <app-vficon
                iconName="cancel"
                class="input-close"
                (click)="openSearchBox(false)"
                tooltip="remove filter"
              ></app-vficon>
              <div class="list box-shadow" *ngIf="columnsDropdown">
                <div class="list-info">Columns</div>
                <div
                  *ngIf="typedColumn === '' || 'all'.includes(typedColumn.toLowerCase())"
                  class="list-item"
                  (click)="selectColumn({ title: 'all', label: 'ALL' })"
                >
                  ALL (Default)
                </div>
                <ng-container *ngFor="let column of sortedColumns">
                  <div
                    class="list-item"
                    *ngIf="column.searchable && column.title && (column.label || column.title).includes(typedColumn)"
                    (click)="selectColumn(column)"
                  >
                    {{ column.label || column.title }}
                  </div>
                </ng-container>
              </div>
            </div>
            <div
              *ngIf="!isSearchBoxOpen"
              class="col text-input pt-3 pb-2"
              (click)="openSearchBox(true)"
              data-cy="search-text-indicator"
            >
              Click here to search
            </div>
          </div>
        </form>
        <div *ngIf="!disableDownload" class="dropdown d-flex align-items-center pl-4" style="margin-left: auto">
          <button
            class="btn"
            type="button"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            data-cy="download-dropdown"
          >
            <app-vficon iconName="download" iconPack="source-mid-render-light-icons"></app-vficon>
          </button>
          <div class="dropdown-menu dropdown-menu-right">
            <button class="dropdown-item" (click)="clickDownload('all')" data-cy="dl-all">
              Download {{ isDynamic ? "Loaded" : "All" }}
            </button>
            <button *ngIf="filtered" class="dropdown-item" (click)="clickDownload('filtered')" data-cy="dl-filtered">
              Download Filtered
            </button>
            <button
              *ngIf="downloadSelected"
              class="dropdown-item"
              (click)="clickDownload('selected')"
              data-cy="dl-selected"
            >
              Download Selected
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
