<!-- New generic custom table as per new PCS portal redesign -->

<img id="vf-loader" class="d-block m-auto" *ngIf="loading" src="/assets/images/spinner_logo.svg" alt="spinner" />

<div class="container-fluid table-container" *ngIf="!loading">
  <!-- customise table columns -->
  <app-customize-table-columns
    [columns]="columns"
    *ngIf="showColumnSelectAccordian"
    (changeEvent)="handleColumnSelection($event)"
    [downloadSelected]="selectableRows"
    (downloadEvent)="download($event)"
    [disableDownload]="showFilterAccordian || disableDownload || rows?.length === 0"
    [isDynamic]="dynamicPagination"
  ></app-customize-table-columns>

  <!-- table filter -->
  <app-table-search
    [isDynamic]="dynamicPagination"
    [lowerCaseInput]="lowerCaseInput"
    [columns]="columns"
    *ngIf="showFilterAccordian"
    [filterItems]="inputFilters"
    [downloadSelected]="selectableRows"
    [disableDownload]="disableDownload || rows.length === 0"
    (changeEvent)="handleFiltersChange($event)"
    (downloadEvent)="download($event)"
  ></app-table-search>

  <!-- common table data -->
  <div class="table-responsive box-shadow mb-3">
    <table class="table m-0 box-shadow">
      <thead>
        <tr>
          <ng-container *ngFor="let column of columns; let colIdx = index; trackBy: trackByColumn">
            <th *ngIf="column.selected" [class.checkbox]="!column.title">
              <div [ngClass]="!!column.title && column.searchable ? ['justify-content-between'] : column?.class">
                <p class="d-block" [class.checkbox]="!column.title">
                  <ng-container *ngIf="column.title; else cb">
                    {{ column.label || column.title }}
                    <app-vficon
                      *ngIf="column.tooltip"
                      (click)="column.tooltip ? handleColumnTooltipClick(column.tooltip) : null"
                      class="ml-1 icon-wrapper clickable"
                      [class.clickable]="!!extractUrl(column.tooltip || '')"
                      [tooltip]="column?.tooltip"
                      container="body"
                      placement="left"
                      iconPack="source-system-icons"
                      iconName="info-circle"
                      size="16px"
                      style="margin-top: 16px"
                    ></app-vficon>
                    <span
                      (click)="sortDataByColumn(column, colIdx)"
                      tooltip="Sort data"
                      placement="right"
                      class="clickable px-2 sorting-icon"
                      *ngIf="!!column.title && (column.sortable ?? column.searchable) && (currentPage?.length ?? 0) > 1"
                      [ngSwitch]="sortOrders[column.title]"
                    >
                      <app-vficon
                        *ngSwitchCase="undefined"
                        iconPack="source-system-icons"
                        iconName="chevron-up"
                      ></app-vficon>
                      <app-vficon
                        *ngSwitchCase="'ASC'"
                        iconPack="source-system-icons"
                        iconName="chevron-down"
                      ></app-vficon>
                      <app-vficon
                        *ngSwitchCase="'DESC'"
                        iconPack="source-system-icons"
                        iconName="chevron-up"
                      ></app-vficon>
                    </span>
                  </ng-container>
                  <ng-template #cb>
                    <input
                      class="align-top select-all-cb"
                      *ngIf="showSelectAllCheckbox"
                      type="checkbox"
                      (change)="notifyAllVisibleRows($event, currentPage)"
                      [checked]="selectAllVisibleRows"
                    />
                  </ng-template>
                </p>
              </div>
            </th>
          </ng-container>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let row of currentPage"
          [ngStyle]="{
            background: row[0]?.rowWarning
              ? backgroundColors?.warning
              : row[0]?.rowSuccess
              ? backgroundColors?.success
              : row[0]?.rowDanger
              ? backgroundColors.danger
              : backgroundColors.default
          }"
        >
          <ng-container *ngFor="let column of columns; index as idx; trackBy: trackByColumn">
            <td
              *ngIf="column.selected"
              [class.warning]="row[idx]?.cellWarning"
              [class.success]="row[idx]?.cellSuccess"
              [class.danger]="row[idx]?.cellDanger"
            >
              <ng-container *ngIf="row[idx] === undefined || row[idx]?.type === undefined; else elseBock">
                <p
                  *ngIf="row[idx]?.innerHTML"
                  style="min-width: max-content"
                  [tooltip]="row[idx]?.tooltip"
                  placement="right"
                  [ngClass]="row[idx]?.classes"
                  [ngStyle]="row[idx]?.styles"
                  [innerHTML]="row[idx]?.innerHTML"
                ></p>
                <p
                  *ngIf="!row[idx]?.innerHTML"
                  style="min-width: max-content"
                  class="flex-column"
                  [tooltip]="isArray(row[idx]?.value) ? '' : row[idx]?.tooltip"
                  placement="right"
                  [ngClass]="row[idx]?.classes"
                  [ngStyle]="row[idx]?.styles"
                  [class.align-items-start]="isArray(row[idx]?.value)"
                >
                  <ng-container [ngSwitch]="isArray(row[idx]?.value)">
                    <ng-container *ngSwitchCase="true">
                      <span *ngFor="let value of row[idx]?.value; index as i" [tooltip]="row[idx]?.tooltip?.[i]">
                        <span style="max-width: 41.65rem; word-wrap: break-word">
                          {{ value || "--" }}
                        </span>
                        <app-vficon
                          *ngIf="row[idx]?.icons?.[i]"
                          [iconName]="row[idx]?.icons?.[i]"
                          iconPack="source-system-icons"
                        ></app-vficon>
                      </span>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                      <span style="max-width: 41.65rem; word-wrap: break-word">
                        <span
                          class="truncate d-inline-block"
                          [title]="(row[idx]?.value || '').length > 10 ? row[idx]?.value : ''"
                          >{{ row[idx]?.value || "--" }}</span
                        >
                        <app-vficon
                          class="clickable position-relative"
                          style="bottom: 10px; left: 4px"
                          tooltip="Click to copy"
                          size="14px"
                          (click)="copyValue(row[idx]?.rawValue ?? row[idx]?.value)"
                          *ngIf="
                            !row[idx]?.icons &&
                            (row[idx]?.value || 0).substring &&
                            !emptyValues.includes((row[idx]?.value || '--').trim())
                          "
                          iconName="copy"
                          iconPack="source-system-icons"
                        ></app-vficon>
                      </span>
                      <app-vficon
                        *ngIf="row[idx]?.icons"
                        [iconName]="row[idx]?.icons"
                        iconPack="source-system-icons"
                      ></app-vficon>
                    </ng-container>
                  </ng-container>
                </p>
              </ng-container>
              <ng-template #elseBock>
                <p
                  class="non-editable"
                  [ngSwitch]="row[idx]?.type"
                  style="min-width: max-content"
                  [ngClass]="row[idx]?.classes"
                  [ngStyle]="row[idx]?.styles"
                  [class.checkbox]="row[idx]?.type === 'checkbox'"
                >
                  <input
                    *ngSwitchCase="'checkbox'"
                    type="checkbox"
                    [ngClass]="row[idx]?.childClasses"
                    [ngStyle]="row[idx]?.childStyles"
                    [(ngModel)]="row[idx].value"
                    [tooltip]="row[idx]?.tooltip"
                    [disabled]="row[idx]?.disabled"
                    (change)="notifyChange($event, row[idx]?.data, column.title)"
                  />
                  <textarea
                    *ngSwitchCase="'textarea'"
                    type="text"
                    [ngClass]="row[idx]?.childClasses"
                    [ngStyle]="row[idx]?.childStyles"
                    [tooltip]="row[idx]?.tooltip"
                    [cols]="row[idx]?.columns || 40"
                    [rows]="row[idx]?.rows || 2"
                    placement="right"
                    [disabled]="row[idx]?.disabled"
                    [pattern]="row[idx]?.pattern"
                    [(ngModel)]="row[idx].value"
                    [placeholder]="row[idx].placeholder || ''"
                    (keyup)="
                      row[0].type === 'checkbox' ? (row[0].value = true) : null;
                      notifyChange($event, row[idx]?.data, column.title, null)
                    "
                  ></textarea>
                  <input
                    *ngSwitchCase="'text'"
                    type="text"
                    [ngClass]="row[idx]?.childClasses"
                    [ngStyle]="row[idx]?.childStyles"
                    [tooltip]="row[idx]?.tooltip"
                    placement="right"
                    [disabled]="row[idx]?.disabled"
                    [pattern]="row[idx]?.pattern"
                    [(ngModel)]="row[idx].value"
                    [placeholder]="row[idx].placeholder || ''"
                    (keyup)="
                      row[0].type === 'checkbox' ? (row[0].value = true) : null;
                      notifyChange($event, row[idx]?.data, column.title)
                    "
                  />
                  <select
                    *ngSwitchCase="'select'"
                    [name]="column.title"
                    class="table-cell-select"
                    [(ngModel)]="row[idx].selected"
                    [style.background-color]="
                      (row[idx]?.value || []).includes(row[idx]?.selected) ? null : backgroundColors.danger
                    "
                    [ngClass]="row[idx]?.childClasses"
                    [ngStyle]="row[idx]?.childStyles"
                    [tooltip]="row[idx]?.tooltip"
                    placement="right"
                    [disabled]="row[idx]?.disabled"
                    (change)="
                      row[0].type === 'checkbox' ? (row[0].value = true) : null;
                      notifyChange($event, row[idx]?.data, column.title)
                    "
                  >
                    <option [value]="row[idx].selected" *ngIf="!(row[idx]?.value || []).includes(row[idx].selected)">
                      {{ row[idx].selected }}
                    </option>
                    <option *ngFor="let value of row[idx]?.value" [value]="value">
                      {{ value }}
                    </option>
                  </select>
                  <ng-container *ngSwitchCase="'a'">
                    <ng-container *ngIf="row[idx]?.href">
                      <ng-container [ngSwitch]="isArray(row[idx]?.value)">
                        <ng-container *ngSwitchCase="true">
                          <a
                            *ngFor="let value of row[idx]?.value; index as i"
                            [tooltip]="row[idx]?.tooltip?.[i]"
                            [href]="row[idx]?.href?.[i]"
                            [ngClass]="row[idx]?.childClasses"
                            [ngStyle]="row[idx]?.childStyles"
                            target="_blank"
                          >
                            {{ value || "--" }}

                            <app-vficon
                              *ngIf="row[idx]?.icons?.[i]"
                              [iconName]="row[idx]?.icons?.[i]"
                              iconPack="source-system-icons"
                            ></app-vficon>
                          </a>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                          <a
                            [tooltip]="row[idx]?.tooltip"
                            placement="right"
                            [href]="row[idx]?.href"
                            [ngClass]="row[idx]?.childClasses"
                            [ngStyle]="row[idx]?.childStyles"
                            target="_blank"
                          >
                            {{ row[idx]?.value || "--" }}

                            <app-vficon
                              *ngIf="row[idx]?.icons"
                              [iconName]="row[idx]?.icons"
                              iconPack="source-system-icons"
                            ></app-vficon>
                          </a>

                          <app-vficon
                            class="clickable position-relative ml-2"
                            style="bottom: 2px"
                            tooltip="Click to copy"
                            size="14px"
                            (click)="copyValue(row[idx]?.href ?? row[idx]?.rawValue ?? row[idx]?.value)"
                            [title]="(row[idx]?.value || '').length > 10 ? row[idx]?.value : ''"
                            *ngIf="!row[idx]?.icons && !emptyValues.includes((row[idx]?.value || '--').trim())"
                            iconName="copy"
                            iconPack="source-system-icons"
                          ></app-vficon>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="!row[idx]?.href">
                      <ng-container [ngSwitch]="isArray(row[idx]?.value)">
                        <ng-container *ngSwitchCase="true">
                          <a
                            *ngFor="let value of row[idx]?.value; index as i"
                            [tooltip]="row[idx]?.tooltip?.[i]"
                            [routerLink]="row[idx]?.routerLink?.[i]"
                            [queryParams]="row[idx]?.queryParams?.[i]"
                            [ngClass]="row[idx]?.childClasses"
                            [ngStyle]="row[idx]?.childStyles"
                            target="_blank"
                          >
                            {{ value || "--" }}
                            <app-vficon
                              *ngIf="row[idx]?.icons?.[i]"
                              [iconName]="row[idx]?.icons?.[i]"
                              iconPack="source-system-icons"
                            ></app-vficon>
                          </a>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                          <a
                            [tooltip]="row[idx]?.tooltip"
                            placement="right"
                            [routerLink]="row[idx]?.routerLink"
                            [queryParams]="row[idx]?.queryParams"
                            [ngClass]="row[idx]?.childClasses"
                            [ngStyle]="row[idx]?.childStyles"
                            target="_blank"
                          >
                            {{ row[idx]?.value || "--" }}
                            <app-vficon
                              *ngIf="row[idx]?.icons"
                              [iconName]="row[idx]?.icons"
                              iconPack="source-system-icons"
                            ></app-vficon>
                          </a>
                          <app-vficon
                            class="clickable position-relative ml-2"
                            style="bottom: 2px"
                            tooltip="Click to copy"
                            size="14px"
                            (click)="copyValue(row[idx]?.rawValue ?? row[idx]?.value)"
                            *ngIf="!row[idx]?.icons && !emptyValues.includes((row[idx]?.value || '--').trim())"
                            iconName="copy"
                            iconPack="source-system-icons"
                          ></app-vficon>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <button
                    *ngSwitchCase="'button'"
                    [type]="row[idx]?.type"
                    [ngClass]="row[idx]?.childClasses"
                    [ngStyle]="row[idx]?.childStyles"
                    [tooltip]="row[idx]?.tooltip"
                    placement="right"
                    [disabled]="row[idx]?.disabled"
                    [routerLink]="row[idx]?.routerLink"
                    [queryParams]="row[idx]?.queryParams"
                    (click)="notifyChange($event, row[idx]?.data, column.title)"
                  >
                    {{ row[idx]?.label }}
                  </button>
                  <!-- scheduler -->
                  <span *ngSwitchCase="'scheduler'" class="scheduler">
                    <!-- start/stop time -->
                    <ng-container [ngSwitch]="row[idx]?.subtype">
                      <ng-container *ngSwitchCase="'time'">
                        <span
                          *ngFor="let value of row[idx]?.value; index as i"
                          class="mb-1 d-flex align-items-center"
                          style="width: max-content"
                        >
                          <mat-form-field>
                            <input
                              matInput
                              type="text"
                              [ngxMatTimepicker]="picker"
                              [value]="row[idx].value[i]"
                              [disabled]="row[idx]?.disabled"
                              id="timepickeinput"
                            />
                            <ngx-mat-timepicker
                              #picker
                              (timeSet)="notifyChange($event, row[idx]?.data[i], column.title)"
                            ></ngx-mat-timepicker>
                          </mat-form-field>
                          <span [ngSwitch]="row[idx]?.data[i].action.split('#')[0]">
                            <fa-icon
                              class="mr-2 position-relative text-success"
                              style="top: 2px"
                              *ngSwitchCase="'start'"
                              [icon]="faStart"
                            ></fa-icon>
                            <fa-icon
                              class="mr-2 position-relative text-primary"
                              style="top: 2px"
                              *ngSwitchCase="'stop'"
                              [icon]="faStop"
                            ></fa-icon>
                          </span>
                          <span style="width: max-content"> UTC{{ row[idx]?.offset }} </span>
                        </span>
                      </ng-container>
                      <!-- scheduled days -->
                      <ng-container *ngSwitchCase="'checkbox'">
                        <span *ngFor="let d of row[idx]?.data; index as i">
                          <input
                            type="checkbox"
                            [tooltip]="
                              column.title === 'enabled'
                                ? row[idx]?.permission && d.enabled
                                  ? 'You cannot opt out of scheduler, please reach out to PCS and follow the exemption process'
                                  : 'Click to enable/disable schedule'
                                : ''
                            "
                            placement="right"
                            [disabled]="
                              column.title === 'enabled'
                                ? row[idx]?.disabled ||
                                  (d.enabled && !row[idx]?.permission && d.action.split('#')[0] === 'stop')
                                : row[idx]?.disabled
                            "
                            [(ngModel)]="row[idx].value[i]"
                            (change)="notifyChange($event, row[idx]?.data[i], column.title)"
                          />
                        </span>
                      </ng-container>
                      <!-- exclutions -->
                      <ng-container *ngSwitchCase="'exclude'">
                        <span class="d-flex justify-content-center flex-column exclusions">
                          <ng-container *ngFor="let d of row[idx]?.data; index as i">
                            <ng-container *ngIf="d.exclude.length > 0">
                              <span
                                *ngFor="let e of d.exclude; index as eidx"
                                class="d-inline-flex mb-1 justify-content-center"
                              >
                                <input
                                  type="text"
                                  [tooltip]="
                                    row[idx]?.permission
                                      ? 'AWS tags, these are joined by OR'
                                      : 'You cannot opt out of scheduler, please reach out to PCS and follow the exemption process'
                                  "
                                  [disabled]="row[idx]?.disabled || !row[idx]?.permission"
                                  [pattern]="row[idx]?.pattern"
                                  [style.background-color]="
                                    !row[idx]?.pattern.test(e.key) ? backgroundColors.danger : null
                                  "
                                  [placeholder]="row[idx]?.placeholder + ' key'"
                                  [(ngModel)]="e.key"
                                  (change)="
                                    notifyChange($event, row[idx]?.data[i], column.title, {
                                      action: 'key',
                                      index: eidx
                                    })
                                  "
                                />
                                <input
                                  type="text"
                                  [tooltip]="
                                    row[idx]?.permission
                                      ? 'AWS tags, these are joined by OR'
                                      : 'You cannot opt out of scheduler, please reach out to PCS and follow the exemption process'
                                  "
                                  placement="left"
                                  [disabled]="row[idx]?.disabled || !row[idx]?.permission"
                                  [pattern]="row[idx]?.pattern"
                                  [style.background-color]="
                                    !row[idx]?.pattern.test(e.value) ? backgroundColors.danger : null
                                  "
                                  [placeholder]="row[idx]?.placeholder + ' value'"
                                  [(ngModel)]="e.value"
                                  (change)="
                                    notifyChange($event, row[idx]?.data[i], column.title, {
                                      action: 'value',
                                      index: eidx
                                    })
                                  "
                                />
                                <button
                                  type="button"
                                  class="btn btn-sm btn-danger"
                                  [tooltip]="
                                    row[idx]?.permission
                                      ? 'Delete'
                                      : 'You cannot opt out of scheduler, please reach out to PCS and follow the exemption process'
                                  "
                                  placement="right"
                                  [disabled]="row[idx]?.disabled || !row[idx]?.permission"
                                  (click)="
                                    notifyChange($event, row[idx]?.data[i], column.title, {
                                      action: 'delete',
                                      index: eidx
                                    })
                                  "
                                >
                                  X
                                </button>
                              </span>
                            </ng-container>
                            <button
                              *ngIf="row[idx]?.condition[i]"
                              type="button"
                              class="btn btn-success btn-sm d-block mx-auto my-1 py-2"
                              [tooltip]="
                                row[idx]?.permission
                                  ? 'Add new exclusion'
                                  : 'You cannot opt out of scheduler, please reach out to PCS and follow the exemption process'
                              "
                              placement="right"
                              [disabled]="row[idx]?.disabled || !row[idx]?.permission"
                              (click)="notifyChange($event, row[idx]?.data[i], column.title, { action: 'add' })"
                            >
                              New
                            </button>
                          </ng-container>
                        </span>
                      </ng-container>
                    </ng-container>
                  </span>
                </p>
              </ng-template>
            </td>
          </ng-container>
        </tr>
        <tr *ngIf="findColumnValueTotal || computeMomTrend">
          <ng-container *ngFor="let column of columns; index as idx">
            <td *ngIf="columnNotExcluded(column, idx)">
              <p *ngIf="column.sum" class="find-sum">
                {{ idx === 0 ? "Grand Total" : column.sum ? columnTotal(idx) : "--" }}
              </p>

              <p *ngIf="column.computeMomTrend" class="find-sum">
                {{ idx === 0 ? "Grand Total" : column.computeMomTrend ? columnMomTrend(idx) : "--" }}
              </p>
            </td>
          </ng-container>
        </tr>
        <tr *ngIf="customGrandTotal?.length > 0">
          <ng-container *ngFor="let column of customGrandTotal; index as i">
            <td *ngIf="columnNotExcluded(column, i)">
              <p class="find-sum">
                {{ column?.value }}
              </p>
            </td>
          </ng-container>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- pagination -->
  <app-pagination
    *ngIf="showPagination"
    [class.invisible]="tableRows.length <= pageSize"
    [pageNumber]="pageNumber"
    [isDynamic]="dynamicPagination"
    [exactRows]="exactRows"
    [pageSize]="pageSize"
    (pageChanged)="handlePaginationChange($event)"
    [tableRows]="tableRows"
  ></app-pagination>
</div>
