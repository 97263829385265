import { Injectable } from "@angular/core";
import { PcsApiService } from "./pcs-api.service";
import { catchError, map } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { LoggerService } from "./logger.service";
import { TENANCY_REGEX } from "../constant/tenancies";
import { PermType, UserDetails, UserPermissions } from "../models/tenancies";
import { GCP_PROJ_REGEX } from "../constant/gcp-api";
import { AWS_ACC_REGEX } from "../constant/aws-api";
import { AZURE_SUBS_REGEX } from "../constant/azure-api";
import { OCI_REGEX } from "../constant/oci-api";
import { DRCC_REGEX } from "../constant/drcc-api";
import { CLOUD_PROVIDERS_ALL } from "../constant/llm";
import { CloudProvider } from "../models/vcloud-api";
import { CloudProviderPermissions } from "../models/permissions";

const CREDENTIALS_REGEX = new RegExp("^/credentials/[^/]+$");

@Injectable({
  providedIn: "root",
})
export class AuthorisationService {
  constructor(private pcsApiService: PcsApiService, private logger: LoggerService) {}

  public describeUserPerms(cloudProviders?: CloudProvider[]): Observable<{ [csp: string]: CloudProviderPermissions }> {
    if ((cloudProviders?.length ?? 0) === 0) {
      return this.describeUserPerms(CLOUD_PROVIDERS_ALL);
    }
    return this.pcsApiService
      .getUser({
        resourcePrefix: cloudProviders.length === 1 ? `/cloud-providers/${cloudProviders[0]}` : `/cloud-providers`,
        attributes: ["permissions"],
        permTypes: ["Read"],
      })
      .pipe(
        map((userDetails: UserDetails) => {
          const csps: { [csp: string]: { global: boolean; resources: string[]; summary: string } } = {};

          for (const resource of userDetails?.permissions?.Read ?? []) {
            const csp = resource.split("/")[2];
            if (csps[csp] === undefined) {
              csps[csp] = { global: false, resources: [], summary: "" };
            }
            if (resource === `/cloud-providers/${csp}`) {
              csps[csp].global = true;
            } else {
              csps[csp].resources.push(resource.split("/")[4]);
            }
          }

          for (const csp of [...cloudProviders].sort()) {
            if (csps[csp] === undefined) {
              csps[csp] = { global: false, resources: [], summary: "" };
            }
          }

          for (const [csp, { global, resources }] of Object.entries(csps)) {
            const messages: string[] = [];
            if (!cloudProviders.includes(csp as CloudProvider)) {
              continue;
            }
            let collection = "resources";
            if (csp === "aws") {
              collection = "accounts";
            } else if (csp === "azure") {
              collection = "subscriptions";
            } else if (csp === "gcp") {
              collection = "projects";
            } else if (csp === "drcc" || csp === "oci") {
              collection = "compartments";
            }
            if (global) {
              messages.push(
                `You have <strong class="text-primary">Read</strong> permissions to <u>ALL ${collection}</u> in ${csp.toUpperCase()}`
              );
            } else {
              if (resources.length <= 5 && resources.length > 0) {
                messages.push(
                  `You have <strong class="text-primary">Read</strong> permissions to the following ${collection} in ${csp.toUpperCase()}: ${resources
                    .sort()
                    .map((r) => `<code>${r}</code>`)
                    .join(", ")}.`
                );
              } else if (resources.length > 0) {
                messages.push(
                  `You have <strong class="text-primary">Read</strong> permissions to <u>${
                    resources.length
                  } ${collection}</u> in ${csp.toUpperCase()}`
                );
              } else {
                messages.push(
                  `You don't have <strong class="text-danger">ANY</strong> permissions to view data related to ${csp.toUpperCase()} ${collection}`
                );
              }
            }
            csps[csp].summary = messages.join(". ");
          }
          return csps;
        })
      );
  }

  public getUserPermissions({
    attributes,
    permTypes,
    resourcePrefix,
  }: {
    attributes?: Array<string>;
    permTypes?: Array<string>;
    resourcePrefix?: string;
  } = {}): Observable<UserPermissions> {
    const attributesFinal: string[] = [
      ...new Set([...(attributes ?? ["permissions", "groups", "tenancies"]), ...["current_tenant", "allowed_tenants"]]),
    ].sort();
    return this.pcsApiService
      .getUser({
        resourcePrefix: resourcePrefix ?? "/",
        attributes: attributesFinal,
        permTypes: [...new Set(permTypes ?? ["Read", "Write", "Create", "Delete"])].sort(),
      })
      .pipe(
        map((user) => {
          const permissions: { [permission: string]: boolean } = {};

          permissions.canReadAllTenancies = this.hasAccess("/tenancies", "Read", user);
          permissions.canReadAnyTenancy =
            permissions.canReadAllTenancies ||
            (user.tenancies || []).some((tenancyId) => this.hasAccess(`/tenancies/${tenancyId}`, "Read", user));

          permissions.canCreateTenancies = this.hasAccess("/tenancies", "Create", user);
          permissions.canAddResources = this.hasAccess("/tenancies", "Create", user);

          permissions.canDeleteTenancyAny =
            this.hasAccess("/tenancies", "Delete", user) ||
            this.hasAccessFn((resource) => TENANCY_REGEX.test(resource), "Delete", user);

          permissions.canReadAwsAllAccounts = this.hasAccess("/cloud-providers/aws/accounts", "Read", user);

          permissions.canReadAwsAnyAccount =
            permissions.canReadAwsAllAccounts ||
            this.hasAccessFn((resource) => AWS_ACC_REGEX.test(resource), "Read", user);

          permissions.canAccessPatchMgmtAws =
            permissions.canReadAwsAnyAccount ||
            (this.hasAccess("/cloud-providers/aws/patch-mgmt/deployments", "Read", user) &&
              this.hasAccess("/cloud-providers/aws/accounts", "Read", user));

          permissions.canReadGcpAllProjects = this.hasAccess("/cloud-providers/gcp/projects", "Read", user);

          permissions.canReadGcpAnyProject =
            permissions.canReadGcpAllProjects ||
            this.hasAccessFn((resource) => GCP_PROJ_REGEX.test(resource), "Read", user);

          permissions.canReadAzureAllSubscriptions = this.hasAccess(
            "/cloud-providers/azure/subscriptions",
            "Read",
            user
          );

          permissions.canReadAzureAnySubscription =
            permissions.canReadAzureAllSubscriptions ||
            this.hasAccessFn((resource) => AZURE_SUBS_REGEX.test(resource), "Read", user);

          permissions.canReadOciAllCompartments =
            this.hasAccess("/cloud-providers/oci", "Read", user) ||
            this.hasAccess("/cloud-providers/oci/compartments", "Read", user);

          permissions.canReadOciAnyCompartment =
            permissions.canReadOciAllCompartments ||
            this.hasAccessFn((resource) => OCI_REGEX.test(resource), "Read", user);

          permissions.canReadDrccAllCompartments =
            this.hasAccess("/cloud-providers/drcc", "Read", user) ||
            this.hasAccess("/cloud-providers/drcc/compartments", "Read", user);

          permissions.canReadDrccAnyCompartment =
            permissions.canReadDrccAllCompartments ||
            this.hasAccessFn((resource) => DRCC_REGEX.test(resource), "Read", user);

          permissions.canSeeServiceFacts = this.hasAccess("/services", "Read", user);
          permissions.canSeeApiUsage = permissions.canSeeServiceFacts;
          permissions.canSeeServiceMetrics = permissions.canSeeServiceFacts;

          permissions.canWriteAwsAnyAccount =
            this.hasAccess("/cloud-providers/aws", "Write", user) ||
            this.hasAccess("/cloud-providers/aws/accounts", "Write", user) ||
            this.hasAccessFn((resource) => AWS_ACC_REGEX.test(resource), "Write", user);

          permissions.canWriteGcpAnyProject =
            this.hasAccess("/cloud-providers/gcp", "Write", user) ||
            this.hasAccess("/cloud-providers/gcp/projects", "Read", user) ||
            this.hasAccessFn((resource) => GCP_PROJ_REGEX.test(resource), "Write", user);

          permissions.canWriteAzureAnySubscription =
            this.hasAccess("/cloud-providers/azure", "Write", user) ||
            this.hasAccess("/cloud-providers/azure/subscriptions", "Write", user) ||
            this.hasAccessFn((resource) => AZURE_SUBS_REGEX.test(resource), "Write", user);

          const pcsUserGroups = (user.groups || {}).pcs || [];
          permissions.isPcs = ["pcs", "ops", "devops", "devopslead", "admin"].some((group) =>
            pcsUserGroups.includes(group)
          );

          const cceUserGroups = (user.groups || {}).CCE || [];
          permissions.isCce = ["cce", "ops", "devops", "devopslead", "admin", "mgmt", "product_managers"].some(
            (group) => cceUserGroups.includes(group)
          );

          permissions.isBrm = user.tenancies.some((t) => t.toLowerCase() === "brm");

          permissions.canReadAnyCredentials =
            this.hasAccess("/credentials", "Read", user) ||
            this.hasAccessFn((resource) => CREDENTIALS_REGEX.test(resource), "Read", user);
          permissions.canCreateCredentials = this.hasAccess("/credentials", "Create", user);

          permissions.canAccessPatchMgmtGcp = permissions.canReadGcpAnyProject;

          permissions.canWriteOciAnyCompartment =
            this.hasAccess("/cloud-providers/oci", "Write", user) ||
            this.hasAccess("/cloud-providers/oci/compartments", "Write", user) ||
            this.hasAccessFn((resource) => OCI_REGEX.test(resource), "Write", user);

          permissions.canWriteDrccAnyCompartment =
            this.hasAccess("/cloud-providers/drcc", "Write", user) ||
            this.hasAccess("/cloud-providers/drcc/compartments", "Write", user) ||
            this.hasAccessFn((resource) => DRCC_REGEX.test(resource), "Write", user);

          const result = { user, permissions };

          this.logger.info("refreshed permissions: ", result);

          return result;
        }),
        catchError((e) => {
          this.pcsApiService.displayErrorToUser(e, "Get Your Details");
          return of({
            user: {
              email: "",
              tenancies: [],
              groups: {},
              sign_in_events: [],
              permissions: {},
            },
            permissions: {},
          });
        })
      );
  }

  public canSeeVmsAws(account: string, region: string, user: Partial<UserDetails>): boolean {
    return [
      `/cloud-providers/aws/accounts/${account}`,
      "/cloud-providers/aws/patch-mgmt/instances",
      `/cloud-providers/aws/accounts/${account}/regions/${region}`,
      `/cloud-providers/aws/accounts/${account}/regions/${region}/instances`,
    ].some((r) => this.hasAccess(r, "Read", user));
  }

  public canSeePatchSchedulesGcp(project: string, user: Partial<UserDetails>): boolean {
    return (
      this.hasAccess("/cloud-providers/gcp", "Read", user) ||
      this.hasAccess(`/cloud-providers/gcp/projects/${project}`, "Read", user)
    );
  }

  public canSeeVmsGcp(project: string, user: Partial<UserDetails>): boolean {
    return (
      this.hasAccess("/cloud-providers/gcp", "Read", user) ||
      this.hasAccess(`/cloud-providers/gcp/projects/${project}`, "Read", user)
    );
  }

  public canDeployPatchMgmtGcp(project: string, user: Partial<UserDetails>): boolean {
    return (
      this.hasAccess("/cloud-providers/gcp", "Write", user) ||
      this.canWriteGcpProjects([`/cloud-providers/gcp/projects/${project}`], user)
    );
  }

  public canDeployPatchMgmtAws(account: string, user: Partial<UserDetails>): boolean {
    return (
      this.canWriteAwsAccounts([`/cloud-providers/aws/accounts/${account}`], user) ||
      this.hasAccess("/cloud-providers/aws/patch-mgmt/deployments", "Write", user)
    );
  }

  public canWriteAwsAccounts(accounts: Array<string> = [], user: Partial<UserDetails>): boolean {
    return (
      this.hasAccess("/cloud-providers/aws", "Write", user) ||
      this.hasAccess("/cloud-providers/aws/accounts", "Write", user) ||
      !accounts.some((a) => !this.hasAccess(a, "Write", user))
    );
  }

  public canWriteGcpProjects(projects: Array<string> = [], user: Partial<UserDetails>): boolean {
    return (
      this.hasAccess("/cloud-providers/gcp", "Write", user) ||
      this.hasAccess("/cloud-providers/gcp/projects", "Write", user) ||
      !projects.some((project) => !this.hasAccess(project, "Write", user))
    );
  }

  public canWriteAzureSubscriptions(subscriptions: Array<string> = [], user: Partial<UserDetails>): boolean {
    return (
      this.hasAccess("/cloud-providers/azure", "Write", user) ||
      this.hasAccess("/cloud-providers/azure/subscriptions", "Write", user) ||
      !subscriptions.some((s) => !this.hasAccess(s, "Write", user))
    );
  }

  public canWriteOciCompartments(compartments: Array<string> = [], user: Partial<UserDetails>): boolean {
    return (
      this.hasAccess("/cloud-providers/oci", "Write", user) ||
      this.hasAccess("/cloud-providers/oci/compartments", "Write", user) ||
      !compartments.some((c) => !this.hasAccess(c, "Write", user))
    );
  }

  public canWriteDrccCompartments(compartments: Array<string> = [], user: Partial<UserDetails>): boolean {
    return (
      this.hasAccess("/cloud-providers/drcc", "Write", user) ||
      this.hasAccess("/cloud-providers/drcc/compartments", "Write", user) ||
      !compartments.some((c) => !this.hasAccess(c, "Write", user))
    );
  }

  public hasAccess(resource: string, permission: PermType, user: Partial<UserDetails>): boolean {
    return this.hasAccessFn((x) => x === resource, permission, user);
  }

  public hasAccessFn(check: (x: string) => boolean, permission: PermType, user: Partial<UserDetails>): boolean {
    return (((user || {}).permissions || {})[permission] || []).some(check);
  }

  public canCreateGroup(tenancy: string, user: Partial<UserDetails>): boolean {
    return (
      this.hasAccess("/tenancies", "Create", user) || this.hasAccess(`/tenancies/${tenancy}/groups`, "Create", user)
    );
  }

  public canDeleteGroup(tenancy: string, groupId: string, user: Partial<UserDetails>): boolean {
    return (
      !groupId.startsWith("sys-") &&
      !["tenancy_owners", "tenancy_viewers"].some((g) => g === groupId) &&
      ["/tenancies", `/tenancies/${tenancy}/groups`, `/tenancies/${tenancy}/groups/${groupId}`].some((r) =>
        this.hasAccess(r, "Delete", user)
      )
    );
  }

  public canModifyGroupMemberships(selectedTenancy: string, groupId: string, user: Partial<UserDetails>): boolean {
    return [
      "/tenancies",
      `/tenancies/${selectedTenancy}/groups`,
      `/tenancies/${selectedTenancy}/groups/${groupId}`,
      `/tenancies/${selectedTenancy}/groups/${groupId}/memberships`,
    ].some((resource) => this.hasAccess(resource, "Create", user));
  }

  public canRemoveResources(tenancy: string, user: Partial<UserDetails>): boolean {
    return ["/tenancies", `/tenancies/${tenancy}/resources`].some((resource) =>
      this.hasAccess(resource, "Delete", user)
    );
  }

  public canModifyResourcePermissions(tenancy: string, user: Partial<UserDetails>): boolean {
    return ["/tenancies", `/tenancies/${tenancy}`, `/tenancies/${tenancy}/resources`].some((resource) =>
      this.hasAccess(resource, "Write", user)
    );
  }

  public canPatchVm(accountId: string, region: string, user: Partial<UserDetails>): boolean {
    return [
      "/cloud-providers/aws/patch-mgmt/patch-jobs",
      `/cloud-providers/aws/accounts/${accountId}/patch-jobs`,
      `/cloud-providers/aws/accounts/${accountId}/regions/${region}`,
      `/cloud-providers/aws/accounts/${accountId}/regions/${region}/patch-jobs`,
    ].some((resource) => this.hasAccess(resource, "Create", user));
  }

  public canAnalyseConnectivity(
    accountId: string,
    region: string,
    instanceId: string,
    user: Partial<UserDetails>
  ): boolean {
    return [
      "/cloud-providers/aws/patch-mgmt/connectivity-analysis",
      `/cloud-providers/aws/accounts/${accountId}`,
      `/cloud-providers/aws/accounts/${accountId}/regions/${region}`,
      `/cloud-providers/aws/accounts/${accountId}/regions/${region}/instances`,
      `/cloud-providers/aws/accounts/${accountId}/regions/${region}/instances/${instanceId}`,
      `/cloud-providers/aws/accounts/${accountId}/regions/${region}/instances/${instanceId}/connectivity-analysis`,
    ].some((resource) => this.hasAccess(resource, "Read", user));
  }

  public canBackupVm(accountId: string, region: string, instanceId: string, user: Partial<UserDetails>): boolean {
    return [
      "/cloud-providers/aws/patch-mgmt/snapshots",
      `/cloud-providers/aws/accounts/${accountId}`,
      `/cloud-providers/aws/accounts/${accountId}/regions/${region}`,
      `/cloud-providers/aws/accounts/${accountId}/regions/${region}/instances`,
      `/cloud-providers/aws/accounts/${accountId}/regions/${region}/instances/${instanceId}`,
      `/cloud-providers/aws/accounts/${accountId}/regions/${region}/instances/${instanceId}/snapshots`,
    ].some((resource) => this.hasAccess(resource, "Create", user));
  }

  public canModifyVm(accountId: string, region: string, user: Partial<UserDetails>): boolean {
    return [
      "/cloud-providers/aws/patch-mgmt/instances",
      `/cloud-providers/aws/accounts/${accountId}`,
      `/cloud-providers/aws/accounts/${accountId}/regions/${region}`,
      `/cloud-providers/aws/accounts/${accountId}/regions/${region}/instances`,
    ].some((resource) => this.hasAccess(resource, "Write", user));
  }

  public canDeleteCredential(credentialId: string, user: Partial<UserDetails>): boolean {
    return (
      this.hasAccess("/credentials", "Delete", user) ||
      this.hasAccessFn((r) => r === `/credentials/${credentialId}`, "Delete", user)
    );
  }

  public canReadTenancy(focusTenancy: string, user: Partial<UserDetails>): boolean {
    return this.hasAccess("/tenancies", "Read", user) || this.hasAccess("/tenancies/" + focusTenancy, "Read", user);
  }

  public canWriteCredential(credentialId: string, user: Partial<UserDetails>): boolean {
    return (
      this.hasAccess("/credentials", "Write", user) ||
      this.hasAccessFn((r) => r === `/credentials/${credentialId}`, "Write", user)
    );
  }

  public canRotateCredential(credentialId: string, user: Partial<UserDetails>): boolean {
    return (
      (this.hasAccess("/credentials", "Write", user) ||
        this.hasAccessFn((r) => r === `/credentials/${credentialId}`, "Write", user)) &&
      (this.hasAccess("/credentials", "Create", user) ||
        this.hasAccessFn((r) => r === `/credentials/${credentialId}`, "Create", user))
    );
  }
}
