import { Pipe, PipeTransform } from "@angular/core";

const units: Array<{ label: string; seconds: number }> = [
  { label: "month", seconds: 60 * 60 * 24 * 30 }, // Rough estimate of a month as 30 days
  { label: "day", seconds: 60 * 60 * 24 },
  { label: "hour", seconds: 60 * 60 },
  { label: "minute", seconds: 60 },
  { label: "second", seconds: 1 },
];

@Pipe({
  standalone: true,
  name: "duration",
})
export class DurationPipe implements PipeTransform {
  public transform(milliseconds: number): string {
    if (!milliseconds || milliseconds < 0) return "0 seconds";

    let seconds = Math.floor(milliseconds / 1000);

    const result: string[] = [];

    for (const unit of units) {
      const amount = Math.floor(seconds / unit.seconds);
      if (amount > 0) {
        result.push(`${amount} ${unit.label}${amount > 1 ? "s" : ""}`);
        seconds -= amount * unit.seconds;
      }
    }

    return result.join(", ");
  }
}
